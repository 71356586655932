import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import OnHoverScrollContainer from "./CustomScrollDiv";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme, } from "@mui/material";

import artist1 from './images/Artists/bp_alessandro_safina.png';
import artist2 from './images/Artists/bp_ayhan_sicimoglu.png';
import artist3 from './images/Artists/bp_aysegul_aldinc.png';
import artist4 from './images/Artists/bp_bgko.png';
import artist5 from './images/Artists/bp_cristina_ramos.png';
import artist6 from './images/Artists/bp_dhafer_youssef.png';
import artist7 from './images/Artists/bp_emir_ersoy.png';
import artist8 from './images/Artists/bp_gipsy_kings.png';
import artist9 from './images/Artists/bp_kovacs.png';
import artist10 from './images/Artists/bp_mfo.png';
import artist11 from './images/Artists/bp_nil_karaibrahimgil.png';
import artist12 from './images/Artists/bp_placido_domingo.png';
import artist13 from './images/Artists/bp_serdar_ortac.png';
import artist14 from './images/Artists/bp_suzan_kardes.png';
import artist15 from './images/Artists/bp_yonca_lodi.png';
import artist16 from './images/Artists/bp_yuksek_sadakat.png';
import artist17 from './images/Artists/bp_zara.png';
import artist18 from './images/Artists/exc_buika.png';
import artist19 from './images/Artists/bp_goran.png';
import artist20 from './images/Artists/bp_yasmin_levy.png';
import artist21 from './images/Artists/bp_evgeny.png';
import artist22 from './images/Artists/bp_buyuk_ev_ablukada.png';
import artist23 from './images/Artists/bp_karsu.png';
import artist24 from './images/Artists/bp_mor_ve_otesi.png';
import artist25 from './images/Artists/bp_Simge.jpg';
import artist26 from './images/Artists/bp_Haïdouti Orchestar.JPG';
import artist27 from './images/Artists/bp_Gipsy Kings feat Tonino Baliardo.jpg';
import artist28 from './images/Artists/bp_BGKO.jpg';
import artist29 from './images/Artists/exc_fuat_guner.png';
import artist30 from './images/Artists/yerli1_BanuSavaş.jpg';
import artist31 from './images/Artists/yerli2_CandanErcetin.jpg';
import artist32 from './images/Artists/yabanci2_Blue.jpg';
import artist33 from './images/Artists/yabanci3_Dire Straits Legacy.jpg';
import artist34 from './images/Artists/yabanci1_Aziza Mustafa Zadeh.jpg';
import artist35 from './images/Artists/yabanci4_Monica Molina.jpg';
import artist36 from './images/Artists/yerli3_MertDemir.jpg';
import artist37 from './images/Artists/yabanci5_Emma Shapplin.jpg';
import artist38 from './images/Artists/yabanci6_Mireille.jpg';


const yabanciArtist = [
    
    {photo: artist12, name:"Placido Domingo"},
    {photo: artist27, name:"Gipsy Kings feat Tonino Baliardo"},
    {photo: artist8, name:"Gipsy Kings feat Andre Reyes"},
    {photo: artist38, name:"Mireille Mathieu"},
    {photo: artist18, name:"Buika"},
    {photo: artist32, name:"Blue"},
    {photo: artist1, name:"Alessandro Safina"},
    {photo: artist37, name:"Emma Shapplin"},
    {photo: artist33, name:"Dire Straits Legacy"},
    {photo: artist6, name:"Dhafer Youssef"},
    {photo: artist34, name:"Aziza Mustafa Zadeh"},
    {photo: artist21, name:"Evgeny Grinko"},
    {photo: artist20, name:"Yasmin Levy"},
    {photo: artist35, name:"Monica Molina"},
    {photo: artist9, name:"Kovacs"},
    {photo: artist19, name:"Goran Bregovic"},
    {photo: artist4, name:"Barcelona Gipsy Balkan Orchestra"},
    {photo: artist26, name:"Haïdouti Orchestar"},

    // {photo: artist5, name:"Cristina Ramos"},
];

const yerliArtist = [
    
    {photo: artist2, name:"Ayhan Sicimoğlu & Latin All Star"},
    {photo: artist3, name:"Ayşegül Aldinç"},
    {photo: artist30, name:"Banu Savaş"},
    {photo: artist22, name:"Büyük Ev Ablukada"},
    {photo: artist31, name:"Candan Erçetin"},
    {photo: artist7, name:"Emir Ersoy"},
    {photo: artist29, name:"Fuat Güner"},
    {photo: artist23, name:"Karsu"},
    {photo: artist36, name:"Mert Demir"},
    {photo: artist10, name:"MFÖ"},
    {photo: artist24, name:"Mor ve Ötesi"},
    {photo: artist11, name:"Nil Karaibrahimgil"},
    {photo: artist13, name:"Serdar Ortaç"},
    {photo: artist25, name:"Simge"},
    {photo: artist14, name:"Suzan Kardeş"},
    {photo: artist15, name:"Yonca Lodi"},
    {photo: artist16, name:"Yüksek Sadakat"},
    {photo: artist17, name:"Zara"},

];


const titleStyle={
    fontFamily:'Poppins',
    fontWeight: 600,
    padding:5,
    margin:0,
    color: 'white',

}
const ContainerStyle = {
    
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
    padding: 0,
    // height: 800, scroll iptal edildiği için kapatıldı.
    
}
const GridStyle = {
    margin:0,
    width: '100%',
    paddingRight: 0,

}
const ContainerStyleMobile = {
    
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0
    // height: 800, scroll iptal edildiği için kapatıldı.
    
}
const GridStyleMobile = {
    margin: 0,
    width: '100%',
    alignItems: 'center'
}

const ArtistCard = ( bpArtist, index ) => {

    const theme = useTheme();
    const isDownMD = useMediaQuery(theme.breakpoints.down("md"));

    return (
      <>
        
        {isDownMD ? (
            
        <Grid key={index} item sx={{pt:"10px !important", pl:"0px !important", alignItems: "center" }} >
            <Card sx={{ width: 310, height: 240, borderRadius: 0, mb:5,}}>
                <img src={bpArtist.photo} width={'100%'} objectFit="cover"/>
                <CardContent sx={{background:'#46328F', p:"0px !important", display: 'flex',  justifyContent: 'center' }}>
                    <Typography style={titleStyle}>
                        {bpArtist.name}
                    </Typography>
                </CardContent>
            </Card>
        </Grid> 

        ):(

        <Grid key={index} item sx={{pt:"10px !important", pr: "5px",}} >
            <Card sx={{ width: 310, height: 240, borderRadius: 0, mb:5,}}>
                <img src={bpArtist.photo} width={'100%'} objectFit="cover"/>
                <CardContent sx={{background:'#46328F', p:"0px !important", display: 'flex',  justifyContent: 'center' }}>
                    <Typography style={titleStyle}>
                        {bpArtist.name}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        )} 
        
      </>
    )
  }

export default function ArtistListComp() {

    const theme = useTheme();
    const isDownMD = useMediaQuery(theme.breakpoints.down("md"));

  return (

    <div style={{paddingBottom: 20, paddingLeft: 10, paddingRight: 10}}>
        {isDownMD ? (
            <Container style={ContainerStyleMobile} maxWidth="xxl">
                {/* <OnHoverScrollContainer> */}
                    <Grid container  direction="column" justifyContent="flex-start" alignItems="center" style={GridStyleMobile}>
                        {yabanciArtist.map(ArtistCard)}                                                
                    </Grid>
                    
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>

                    <Grid container  direction="column" justifyContent="flex-start" alignItems="center" style={GridStyleMobile}>
                        {yerliArtist.map(ArtistCard)}                                                
                    </Grid>
                {/* </OnHoverScrollContainer>        */}
            </Container>
        ) : (
            <Container style={ContainerStyle} maxWidth="xxl">
                {/* <OnHoverScrollContainer> */}
                    <Grid container  direction="row" justifyContent="flex-start" alignItems="center" style={GridStyle} spacing={5}>
                        {yabanciArtist.map(ArtistCard)}                                                
                    </Grid>
                    
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
    
                    <Grid container  direction="row" justifyContent="flex-start" alignItems="center" style={GridStyle} spacing={5}>
                        {yerliArtist.map(ArtistCard)}                                                
                    </Grid>
                {/* </OnHoverScrollContainer>        */}
            </Container>
        )}
    </div>
  )
}
