import React, { Component } from "react";
import { useState } from 'react';
import { useMediaQuery, useTheme, Container, } from "@mui/material";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EventCard from "./EventCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "react-i18next";

const events = [
  { image: './Events/AlessandroSafina .jpg', title: "Buika Uniq İstanbul", date: "Buika Uniq İstanbul"},
  { image: './Events/AlessandroSafina .jpg', title: "Buika Uniq İstanbul", date: "Buika Uniq İstanbul"},
  { image: './Events/AlessandroSafina .jpg', title: "Buika Uniq İstanbul", date: "Buika Uniq İstanbul"},
  { image: './Events/AlessandroSafina .jpg', title: "Buika Uniq İstanbul", date: "Buika Uniq İstanbul"},
  { image: './Events/AlessandroSafina .jpg', title: "Buika Uniq İstanbul", date: "Buika Uniq İstanbul"},


];

// function EventCarousel() {

//     const responsive = {
//         superLargeDesktop: {
//           // the naming can be any, depends on you.
//           breakpoint: { max: 4000, min: 2500 },
//           items: 6
//         },
//         desktop: {
//           breakpoint: { max: 2500, min: 1024 },
//           items: 4
//         },
//         tablet: {
//           breakpoint: { max: 1024, min: 800 },
//           items: 3
//         },
//         mobile: {
//           breakpoint: { max: 800, min: 600 },
//           items: 2
//         },
//         phone: {
//           breakpoint: { max: 600, min: 0 },
//           items: 1
//         }
//       };


//   return (
//     <div style={{ margin: 40}}>

//         <Carousel responsive={responsive} >
//             <EventCard/>
//             <EventCard/>
//             <EventCard/>
//             <EventCard/>
//             <EventCard/>
//             <EventCard/>
//             <EventCard/>
//             <EventCard/>
//             <EventCard/>
//         </Carousel>

//     </div>
//   )
// }

// export default EventCarousel


function EventCarousel() {

  const {t, i18n}= useTranslation();

const [value, setValue] = useState();
const theme = useTheme();
const isMatch = useMediaQuery(theme.breakpoints.down("md"));
const isDownMD = useMediaQuery(theme.breakpoints.down("sm"));
const slideCheck = isMatch ? 2 : 3 ;
const slideNumber = isDownMD ? 1 : slideCheck;

    const settings = {
      className: "center",
      centerMode: false,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: slideNumber,
       speed: 700
    };
  return (
      <div style={{ margin: 40, marginTop:30}}>
        <Slider {...settings} >
          
          <h3><EventCard image="/images/Events/Featured1.jpg" title="BGKO feat Candan Erçetin" date={t("events.BGKO")} link="https://biletinial.com/tr-tr/muzik/barcelona-gipsy-balkan-orchestra-feat-candan-ercetin"/></h3>
          <h3><EventCard image="/images/Events/Featured2.jpg" title="Gipsy Kings feat Tonino Baliardo" date={t("events.Gipsy")} link="https://iticket.az/en/events/concerts/gipsy-kings-feat-tonino-baliardo/130630" /></h3>
          <h3><EventCard image="/images/Events/Featured3.jpg" title="Dire Straits Legacy" date={t("events.Dire")} link="https://biletinial.com/tr-tr/muzik/dire-straits-legacy"/></h3>

          {/* <h3><EventCard image="/images/Events/Monica2.jpg" title="Monica Molina" date={t("events.Monica")} link="https://www.passo.com.tr/en/event/monica-molina-zorlupsm-turkcell-sahnesi-biletleri/5239323"/></h3> */}

          {/* <h3><EventCard image="/images/Events/Cimri.jpg" title="Cimri" date={t("events.Cimri")} /></h3>
          <h3><EventCard image="/images/Events/MorOtesi.jpg" title="Mor ve Ötesi" date={t("events.Mor ve Ötesi")} /></h3>
          <h3><EventCard image="/images/Events/YasminLevi.jpg" title="Yasmin Levi" date={t("events.Yasmin Levi")} /></h3>
          <h3><EventCard image="/images/Events/Karsu.jpg" title="Karsu" date={t("events.Karsu")} /></h3>
          <h3><EventCard image="/images/Events/GoranBregovic.jpg" title="Goran Bregovic" date={t("events.Goran Bregovic")} /></h3>
          <h3><EventCard image="/images/Events/BuyukevAbluka.jpg" title="Büyük Ev Ablukada" date={t("events.Büyük Ev Ablukada")} /></h3>
          <h3><EventCard image="/images/Events/Dirmit.jpg" title="Sevgili Arsız Ölüm - Dirmit" date={t("events.Sevgili Arsız Ölüm - Dirmit")} /></h3>
          <h3><EventCard image="/images/Events/Buika.jpg" title="Buika" date={t("events.Buika")} /></h3>
          <h3><EventCard image="/images/Events/DeliBayrami.jpg" title="Deli Bayramı" date={t("events.Deli Bayramı")} /></h3>
          <h3><EventCard image="/images/Events/Evgeny-Grinko.jpg" title="Evgeny Grinko" date={t("events.Evgeny Grinko")} /></h3>
          <h3><EventCard image="/images/Events/AlessandroSafina.jpg" title="Alessandro Safina" date={t("events.Alessandro Safina")} /></h3>
          <h3><EventCard image="/images/Events/GokhanTurkmen.jpg" title="Gökhan Türkmen" date={t("events.Gökhan Türkmen")} /></h3>
          <h3><EventCard image="/images/Events/Gulsen.jpg" title="Gülşen" date={t("events.Gülşen")} /></h3>
          <h3><EventCard image="/images/Events/Monica.jpg" title="Monica Molina" date={t("events.Monica Molina")} /></h3>
          <h3><EventCard image="/images/Events/ZenginMutfagi.jpg" title="Zengin Mutfağı" date={t("events.Zengin Mutfağı")} /></h3>
          <h3><EventCard image="/images/Events/EmmaShapplin.jpg" title="Emma Shapplin" date={t("events.Emma Shapplin")} /></h3> */}


        </Slider>
      </div>
  )
}

export default EventCarousel