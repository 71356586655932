import React, { useState } from 'react';
import ArtistCard from './ArtistCard';
import ArtistCardExclusive from './ArtistCardExclusive';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArtistListComp from './ArtistListComp'
import ArtistListExclusiveComp from './ArtistListExclusiveComp'
import Container from '@mui/material/Container'
import { useMediaQuery, useTheme, } from "@mui/material";
import language from './Language'
import {useTranslation} from "react-i18next";

const boxStyle ={
  backgroundColor: '#522875',
  opacity: 0.8,
  width: '100%',
  justifyContent: "center",
  display: 'flex',
  marginBottom: 20,
  marginTop: 40
}
const containerStyle ={
  backgroundColor: '#E3E4E4',
  width: '100%',
}
const titleStyle={
  fontFamily:'Poppins',
  fontWeight: 300,
  padding: 10,
  margin: 10,
  color: 'white',
  textAlign: 'justify'

}
const gridItemStyle = {
  width: '100%',
}

function Artists() {

  const [value, setValue] = useState();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const isDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const {t, i18n}= useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  // {language[localStorage.getItem("selectedLanguage")].test}
  
  return (

    <div style={{backgroundColor: '#E3E4E4', width:'100%', paddingBottom: 30, height: '100%'}}>
      <Container maxWidth="lg">
        <Grid container direction="column" justifyContent="center" alignItems="center" style={containerStyle}>
          <Grid item style={gridItemStyle}>
              <Box style={boxStyle}>
                <Typography  style={titleStyle}>{t("announcment.text")}
                </Typography>
              </Box>
          </Grid>
          {/* <Grid item style={gridItemStyle}>
            <ArtistListExclusiveComp/>
          </Grid> */}
          {/* {isDownMD ? (
            <Grid item style={gridItemStyle}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center" style={gridItemStyle}>
              <Grid item>
                <ArtistCardExclusive itemHeight={350} itemWidth={300}/>
              </Grid>
              <Grid item>
                <ArtistCardExclusive itemHeight={350} itemWidth={300}/>
              </Grid>
              <Grid item>
                <ArtistCardExclusive itemHeight={350} itemWidth={300}/>
              </Grid>
              <Grid item>
                <ArtistCardExclusive itemHeight={350} itemWidth={300}/>
              </Grid>                     
            </Grid>
          </Grid>
            ):(  
          <Grid item style={gridItemStyle}>
              <Grid container direction="column" style={gridItemStyle}>
                  <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" height="430px">
                      <Grid item sx={{pr:'20%'}}>
                        <ArtistCardExclusive itemHeight={300} itemWidth={410}/>
                      </Grid>
                      <Grid item>
                        <ArtistCardExclusive itemHeight={300} itemWidth={410}/>
                      </Grid>
                    </Grid>
                  </Grid>                
                  <Grid item>
                    <Grid container direction="row" justifyContent="flex-end" height="430px">
                      <Grid item>
                        <ArtistCardExclusive itemHeight={300} itemWidth={410} />
                      </Grid>
                      <Grid item sx={{pl:'20%'}}>
                        <ArtistCardExclusive itemHeight={300} itemWidth={410}/>
                      </Grid>
                    </Grid>
                  </Grid>                
              </Grid>
          </Grid>
          )}   */}
          <Grid item style={gridItemStyle}>
          {/* <Box style={boxStyle}>
                <Typography  style={titleStyle}>Booking / Promoting</Typography>
              </Box> */}
          </Grid>
          <Grid item style={gridItemStyle}>
            <ArtistListComp/>
          </Grid>         
        </Grid>
      </Container>
    </div>
  )
}

export default Artists