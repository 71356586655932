import React from 'react'
import BigSlider from "./BigSlider";
import {useTranslation} from "react-i18next";

import { useMediaQuery, useTheme, } from "@mui/material";


  const containerLargeStyles = {
    width: "100%",
    height: "600px",
    margin: "0 auto",
  };
  const containerSmallStyles = {
    width: "100%",
    height: "400px",
    margin: "0 auto",
  };


function SliderComp() {

    const theme = useTheme();
    const isDownMD = useMediaQuery(theme.breakpoints.down("md"));
    const {t, i18n}= useTranslation();

    const slides = [
      { url: t('slider.WebSlider1'), title: "Mirelle Mathieu", link:"" },
      { url: t('slider.WebSlider2'), title: "Blue", link:"" },
      { url: t('slider.WebSlider3'), title: "Gipsy Kings", link:"" },
      { url: t('slider.WebSlider4'), title: "BGKO", link:"" },
      { url: t('slider.WebSlider5'), title: "Buika - Kibariye", link:"" },

    ];
    const smallSlides = [
      { url: t('slider.MobileSlider1'), title: "Mirelle Mathieu", link:"" },
      { url: t('slider.MobileSlider2'), title: "Blue", link:"" },
      { url: t('slider.MobileSlider3'), title: "Gipsy Kings", link:"" },
      { url: t('slider.MobileSlider4'), title: "BGKO", link:"" },
      { url: t('slider.MobileSlider5'), title: "Buika - Kibariye", link:"" },
  
    ];
  return (
    <>
    {isDownMD ? ( 
        <div style={containerSmallStyles}>
            <BigSlider slides={smallSlides}/>
        </div>
    ):(
        <div style={containerLargeStyles}>
            <BigSlider slides={slides}/>
        </div>
    )}
    </>
    
  )
}

export default SliderComp